export default{
        data() {
            return {
              fields: [
                { key: "name", label: "العنوان" },
                { key: "icon", label: "الصورة" },
                { key: "actions", label: "" }
              ],
              estatesFormSchema: {
                title: {
                  component: "FormInput",
                  key: "name",
                  attrs: {
                    id: "name",
                    label: "العنوان",
                    rules: "required",
                  },
                },
                images: {
                  key: "icon",
                },
              }
            };
          },
    
}